<template>
  <div class="OKRDetails">
    <div class="details">
      <div class="title">
        详情
      </div>
    </div>
    <div class="content">
      <input class="int" type="text" placeholder="请添加标题" />
      <div class="progressThan">
        <div class="progress">
          <div>进度</div>
          <span>0%</span>
        </div>
        <div class="weight">
          <div>权重</div>
          <span>100%</span>
        </div>
        <div class="totalScore">
          <div>总分</div>
          <span>0.0</span>
        </div>
      </div>
      <div class="founder">
        <div class="information">创建人</div>
        <div class="theName">陈柱全(2021.03.03)</div>
      </div>
      <div class="starStop">
        <div class="lintTime">起止时间</div>
        <div class="lintDate">2020.09.24-2021.03.03</div>
      </div>
      <div class="projectPersonnel">
        <div class="responsiblePerson">
          <div class="nickName">主要负责人(负责人只能添加一个)</div>
          <div class="headPortrait">
            <div class="itemPortrait">
              <img class="img" src="@/assets/images/logo.png" alt="" />
              <div>陈柱全</div>
            </div>
            <div class="icon">
              <i class="iconfont">&#xe676;</i>
              <i class="iconfont">&#xe675;</i>
            </div>
          </div>
        </div>
        <div class="participants">
          <div class="secondary">参与人员</div>
          <div class="headPortrait">
            <div class="itemPortrait">
              <img class="img" src="@/assets/images/logo.png" alt="" />
              <div>陈柱全</div>
            </div>
            <div class="icon">
              <i class="iconfont">&#xe676;</i>
              <i class="iconfont">&#xe675;</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn">
      <button class="cancel">取消</button>
      <button class="confirm">确认</button>
    </div>
    <!-- 评论 -->
    <!-- <div class="comments">
      <div class="title">评论</div>
    </div> -->
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.OKRDetails {
  //   position: relative;
  width: 520px;
  height: 1201px;
  opacity: 1;
  background-color: #fff;
  .details {
    padding: 18px 23px;
    border-bottom: 1px solid #ccc;
    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #3f4254;
      opacity: 1;
    }
  }
  .content {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 18px;
    input:focus {
      outline: none;
    }
    .int {
      text-align: center;
      border: none;
      width: 100%;
      padding: 15px;
      background: #ffffff;
      border: 1px solid #3370ff;
      opacity: 1;
      border-radius: 5px;
    }
    .progressThan {
      margin-top: 15px;
      display: flex;
      justify-content: flex-start;
      .progress {
        padding-left: 25px;
        padding-right: 35px;
      }
      .totalScore {
        padding-left: 35px;
      }
    }
    .founder {
      margin-top: 26px;
      .information {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #b5b6c7;
        opacity: 1;
      }
      .theName {
        margin-top: 9px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #3f4254;
        opacity: 1;
      }
    }
    .starStop {
      margin-top: 16px;
      .lintTime {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #b5b6c7;
        opacity: 1;
      }
      .lintDate {
        margin-top: 9px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #3f4254;
        opacity: 1;
      }
    }
    .projectPersonnel {
      margin-top: 27px;
      .responsiblePerson {
        display: flex;
        flex-direction: column;
        .nickName {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #b5b6c7;
          opacity: 1;
        }
        .headPortrait {
          position: relative;
          margin-top: 9px;
          .itemPortrait {
            float: left;
            .img {
              width: 36px;
              height: 36px;
              background: rgba(0, 0, 0, 0);
              border-radius: 50%;
              opacity: 1;
              border: 1px solid #ccc;
              margin-bottom: 6px;
            }
            div {
              font-size: 12px;
              font-weight: 400;
              line-height: 17px;
              color: #3f4254;
              opacity: 1;
            }
          }
          .icon {
            position: absolute;
            left: 35px;
            top: -8px;
            .iconfont {
              float: left;
              padding-left: 18px;
              font-size: 36px;
              color: #c1c5cc;
            }
          }
        }
      }
      .participants {
        margin-top: 13px;
        .secondary {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #b5b6c7;
          opacity: 1;
        }
        .headPortrait {
          position: relative;
          margin-top: 9px;
          .itemPortrait {
            float: left;
            .img {
              width: 36px;
              height: 36px;
              background: rgba(0, 0, 0, 0);
              border-radius: 50%;
              opacity: 1;
              border: 1px solid #ccc;
              margin-bottom: 6px;
            }
            div {
              font-size: 12px;
              font-weight: 400;
              line-height: 17px;
              color: #3f4254;
              opacity: 1;
            }
          }
          .icon {
            position: absolute;
            left: 35px;
            top: -8px;
            .iconfont {
              float: left;
              padding-left: 18px;
              font-size: 36px;
              color: #c1c5cc;
            }
          }
        }
      }
    }
  }
  .btn {
    padding: 0 15px;
    padding-top: 80px;
    float: right;
    .cancel {
      padding: 6px 18px;
      margin-right: 15px;
      outline: none;
      border: none;
    }
    .cancel:hover {
      background-color: #2994ff;
      color: #fff;
    }
    .confirm {
      padding: 6px 18px;
      outline: none;
      border: none;
    }
    .confirm:hover {
      background-color: #2994ff;
      color: #fff;
    }
  }
  .comments {
    float: left;
    padding: 0 15px;
    .title {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      color: #3f4254;
      opacity: 1;
    }
  }
}
</style>
